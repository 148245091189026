<template>
  <a-card>
    <a-page-header :title="'目标看板'" />
    <a-form-model ref="form" :model="form" v-bind="layout">
      <a-form-model-item label="统计周期" :labelCol="{ span: 4 }">
        <a-radio-group v-model="form.cycleType">
          <a-radio :value="1"> 累计 </a-radio>
          <a-radio :value="2"> 今日 </a-radio>
          <a-radio :value="3"> 昨日 </a-radio>
          <a-radio :value="4"> 近7日 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-row type="flex">
        <a-col :span="6">
          <a-form-model-item label="广告主">
            <a-input v-model="form.advertiserName" placeholder="请输入" :allow-clear="true" />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="投手">
            <a-select
              v-model="form.operatorId"
              placeholder="请选择"
              allow-clear
              show-search
              option-filter-prop="children"
              style="width: 100%"
            >
              <a-select-option v-for="item in pitcherList" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="隐藏已结束目标">
            <a-select v-model="form.hidden">
              <a-select-option :value="1"> 是 </a-select-option>
              <a-select-option :value="0"> 否 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="隐藏无异常目标">
            <a-select v-model="form.hiddenNotException">
              <a-select-option :value="1"> 是 </a-select-option>
              <a-select-option :value="0"> 否 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-button type="primary" @click="handleSearch" style="margin-bottom: 10px">查询</a-button>
    <div style="margin-bottom: 10px">数据对比广告平台有30分钟延迟</div>
    <a-table
      :loading="loading"
      :columns="targetBoardColumns"
      :data-source="dataList"
      :row-key="(record) => record.id"
      :pagination="false"
    >
      <div slot="action" slot-scope="text, record">
        <base-button :type="'link'" :title="'修改'" @onClickBtn="editTarget(record.id)"></base-button>
        <base-button :type="'link'" :title="'删除'" @onClickBtn="deleteTarget(record.id)"></base-button>
      </div>
    </a-table>
    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </a-card>
</template>

<script>
import { targetBoardColumns } from './data/constants';

export default {
  data() {
    return {
      targetBoardColumns,
      layout: {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
      },
      form: {
        advertiserName: undefined,
        cycleType: 1,
        hidden: 1,
        hiddenNotException: 0,
        operatorId: undefined,
      },
      pitcherList: [],
      dataList: [],
      pagination: {
        current: 1,
        pageSize: 100,
        total: 0,
      },
      loading: false,
    };
  },
  methods: {
    getUsersList() {
      this.$api.core.pphtManage.getUsersList({ client_id: 'bfz', page_size: 1000 }).then((res) => {
        if (res.code == 200) {
          this.pitcherList = res.data.list;
        } else {
          this.$message.error(`获取投手列表失败，${res.message}`);
        }
      });
    },
    handleSearch() {
      this.pagination.current = 1;
      this.getDataList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      const _form = this.form;
      let params = Object.assign(_form, {
        page: this.pagination.current,
        size: this.pagination.pageSize,
      });
      this.$api.core.deliverManage
        .getTargetBoardList(params)
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.dataList = res.data.list || [];
            this.pagination.total = res.data.total || 0;
          } else {
            this.dataList = [];
            this.pagination.total = 0;
            console.error(`获取列表失败${res}`);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getUsersList();
    this.getDataList();
  },
};
</script>

<style scoped lang="scss">
::v-deep .ant-page-header {
  padding-left: 0;
  padding-right: 0;
}
</style>
